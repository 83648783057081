import client from '../client';

import { RECEIVE_CONTENT } from '../reducers/content';

export const orgSiteSettingsQuery = () => `{
        "settings": *[_id == "orgSiteSettings"][0]
    }`;

export function queryContent(query) {
  return async (dispatch) => {
    const result = await client.fetch(query);
    dispatch({
      type: RECEIVE_CONTENT,
      content: [result.settings]
    });
  }
}