import Head from 'next/head';
import pageTitleFormatter from '../lib/pageTitleFormatter';
import React from 'react';


const Metatags = (props) => {
  const {
    title,
    description,
    canonical,
    image,
    titleFormatter = pageTitleFormatter
  } = props;
  return <Head>
    <title>{titleFormatter(title)}</title>
    <meta name='referrer' content='always' />
    <meta name='title' content={titleFormatter(title)} />
    <meta name='description' content={description} />
    <link rel='canonical' href={canonical} />

    <meta property='og:type' content='website' />
    <meta property='og:url' content={canonical} />
    <meta property='og:title' content={titleFormatter(title)} />
    <meta property='og:description' content={description} />
    {image && <meta property='og:image' content={image} />}

    <meta property='twitter:card' content='summary_large_image' />
    <meta property='twitter:url' content={canonical} />
    <meta property='twitter:title' content={titleFormatter(title)} />
    <meta property='twitter:description' content={description} />
    {image && <meta property='twitter:image' content={image} />}
  </Head>;
}

export default Metatags;