import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { orgSiteSettingsQuery, queryContent } from '../actions/content';

const useSiteSettings = () => {

  const orgSiteSettings = useSelector(state => state?.contentStore?.contentTypes?.orgSiteSettings?.orgSiteSettings, shallowEqual);
  const dispatch = useDispatch();

  useEffect(async () => {
    if (!orgSiteSettings) {
      dispatch(queryContent(orgSiteSettingsQuery()));
    }
  }, [orgSiteSettings]);

  return orgSiteSettings;
};

export default useSiteSettings;