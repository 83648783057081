import React from 'react';
import client from '../client';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(client);

const StyledPicture = styled('picture')(({ theme }) => ({
  width: '100%'
}))

const StyledImg = styled('img')(({ theme }) => ({
  width: '100%'
}))

const MainImageGrid = styled(Grid)(({ theme }) => ({
  lineHeight: 0,
  maxHeight: '100vh',
  overflow: 'hidden',
  [theme.breakpoints.down('xs')]: {
    order: -1
  }
}))

const MainCopyGrid = styled(Grid, {
  shouldForwardProp: (p) => p !== 'paddingTop'
})(({ theme, paddingTop }) => ({
  paddingTop: paddingTop ? paddingTop : '64px',
  margin: 'auto',
  paddingLeft: '9%',
  paddingRight: '9%',
  [theme.breakpoints.down('xs')]: {
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(10)
  }
}))

const SplitFifty = (props) => {
  const {
    imageRight = true,
    mainImage,
    paddingTop
  } = props;

  const imageGridItem = (
    <MainImageGrid item xs={12} sm={4} md={6} style={{ lineHeight: 0 }}>
      <StyledPicture>
        <source srcSet={builder.image(mainImage).format('webp').width(732).height(1020).url()} media='(min-width: 960px)' />
        <source srcSet={builder.image(mainImage).format('webp').width(320).height(1020).url()} media='(min-width: 600px)' />
        <source srcSet={builder.image(mainImage).format('webp').width(450).height(325).url()} />
        <StyledImg src={builder.image(mainImage).width(1440).height(723).url()} alt={mainImage.alt} />
      </StyledPicture>
    </MainImageGrid>
  );

  const bodyGridItem = (
    <MainCopyGrid item xs={12} sm={8} md={6} paddingTop={paddingTop}>
      {props.children}
    </MainCopyGrid>
  );

  return (
    <Grid container>
      {imageRight ? bodyGridItem : imageGridItem}
      {imageRight ? imageGridItem : bodyGridItem}
    </Grid>
  )
}

export default SplitFifty;