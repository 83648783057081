import React from 'react';
import { Button } from '@tmap/mmm-style-guide/src/Button';
import { ArrowBack } from '@tmap/mmm-style-guide/src/Icon';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import { Typography } from '@tmap/mmm-style-guide/src/Typography';

import SplitFifty from '../layouts/splitFifty';
import FullWrapper from '../layouts/fullWrapper';
import Metatags from '../components/metatags';
import client from '../client';
import useSiteSettings from '../hooks/useSiteSettings';

import { useRouter } from 'next/router';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(client);

const PreheadTypography = styled(Typography)(({ theme }) => ({
  color: '#C8C1BB',
  fontWeight: 700
}))
const HeaderTypography = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  fontWeight: 700
}))
const BodyTypography = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(7)
}))

const NotFound = (props) => {
  const doc = useSiteSettings();
  const router = useRouter();

  if (!doc) return null;
  return (
    <>
      <Metatags title={'404 Not Found'}
        description={doc.notFoundTitle}
        canonical={process.env.NEXT_PUBLIC_CANONICAL_DOMAIN + '/404'}
        image={builder.image(doc.notFoundImage).url()}
      />
      <FullWrapper headerColor='default'>
        <SplitFifty mainImage={doc.notFoundImage}>
          <PreheadTypography variant='h5'>Error 404</PreheadTypography>
          <HeaderTypography variant='h3' component='h1' gutterBottom>{doc.notFoundTitle}</HeaderTypography>
          <BodyTypography variant='body1'>{doc.notFoundBody}</BodyTypography>
          <Button variant='contained' color='convert' onClick={() => router.back()}><ArrowBack />&nbsp;&nbsp;Go Back</Button>
        </SplitFifty>
      </FullWrapper>
    </>
  )
}

export default NotFound;